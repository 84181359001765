<div class="max-w-[1280px] mx-auto">
  @if(serverRestarting$ | async){
  <div
    class="fixed flex place-items-center w-fit left-[50%] translate-x-[-50%] rounded-md cursor-pointer top-2 px-3 py-2 bg-white text-black z-[1000]"
  >
    <button (click)="reloadCurrentRoute()">Click here to Refresh</button>
    <mat-icon class="ml-2">refresh</mat-icon>
  </div>
  }
  <!-- <div
    *ngIf="!isAndroid()"
    class="h-[56px] w-full"
    [ngClass]="headerBg()"
  ></div> -->
  @if(!hideStatusBar$()){
  <div
    *ngIf="!isAndroid()"
    class="h-[15px] w-full"
    [ngClass]="headerBg()"
  ></div>
  }
  <div *ngIf="routeLoading()?.loading" class="w-full fixed top-0 z-[1000]">
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
  </div>
  <app-no-internet class="z-[10001]" *ngIf="!isOnline()"></app-no-internet>

  <div>
    <router-outlet></router-outlet>
  </div>
</div>

@if((showNavBar())){
<div
  class="fixed bottom-0 w-full h-[80px] flex justify-center place-items-center bg-gradient-to-r from-[#495674] to-[#3D4558]"
>
  <div
    class="w-full bg-transparent h-fit text-white flex place-items-end justify-evenly overflow-x-scroll [&>*>*:nth-child(even)]:hidden xs:[&>*>*:nth-child(even)]:block text-xs sm:text-sm"
  >
    <a
      routerLink="/home/categories"
      class="flex flex-col justify-center place-items-center gap-1"
    >
      <img
        class="h-[30px] w-[34px]"
        src="assets/bottomnav/home{{
          activeRoute() === '/home/categories' ? '_selected' : ''
        }}.png"
        alt="home"
      />
      <span
        [ngStyle]="
          activeRoute() === '/home/categories'
            ? { fontWeight: '600' }
            : {
                fontWeight: '500'
              }
        "
        >Home</span
      >
    </a>
    <a
      routerLink="/home/yashiAi"
      class="flex flex-col justify-center place-items-center gap-1"
    >
      <img
        class="h-[30px] w-[30px]"
        src="assets/bottomnav/ai{{
          activeRoute() === '/home/yashiAi' ? '_selected' : '_selected'
        }}.png"
        alt="achievements"
      />
      <span
        [ngStyle]="
          activeRoute() === '/home/yashiAi'
            ? { fontWeight: '600' }
            : {
                fontWeight: '500'
              }
        "
        >Yashi AI</span
      >
    </a>

    <a
      routerLink="/home/forum"
      class="flex relative flex-col justify-center place-items-center gap-1"
    >
      <img
        class="h-[30px] w-[34px]"
        src="assets/bottomnav/forum.png"
        alt="forum"
      />
      <span
        class="absolute top-0 right-0 place-content-center text-center rounded-full font-semibold text-[12px] h-5 w-5 bg-red-700"
        *ngIf="notificationsCount$().FORUM + messageCount() > 0 &&  (notificationsCount$().FORUM + messageCount())<=99"
        >{{ notificationsCount$().FORUM + messageCount() }}</span>

        <span
        class="absolute top-0 right-0 place-content-center text-center rounded-full font-semibold text-[12px] h-6 w-6 bg-red-700"
        *ngIf="(notificationsCount$().FORUM + messageCount())  > 99 "
        >99+</span
      >
      <span>Discussions</span>
    </a>

    <a
      routerLink="/home/battle-quiz"
      class="flex flex-col justify-center place-items-center gap-1"
    >
      <img
        class="h-[30px] w-[38px]"
        src="assets/bottomnav/battle_blaze.png"
        alt="battleBlaze"
      />
      <span>Battle Blaze</span>
    </a>

    <a
      routerLink="/home/profile"
      class="flex flex-col justify-center place-items-center gap-1"
    >
      <img
        class="h-[30px] w-[30px]"
        src="assets/bottomnav/profile{{
          activeRoute() === '/home/profile' ? '_selected' : ''
        }}.png"
        alt="profile"
      />
      <span
        [ngStyle]="
          activeRoute() === '/home/profile'
            ? { fontWeight: '600' }
            : {
                fontWeight: '500'
              }
        "
        >Profile</span
      >
    </a>
  </div>
</div>
}
<ng-template
  class="flex flex-col text-white gap-16 w-full rounded-none dialog-frame2"
  #updateDialog
>
  <div class="flex flex-col p-4 gap-10 mt-10">
    <img
      class="w-[316px] h-[282px] self-center"
      src="../assets/images/update.gif"
      alt=""
    />
    <div class="text-[32px] text-center text-white font-extrabold mt-5">
      Update Your App
    </div>
    <div
      class="text-center text-[20px] leading-relaxed overflow-y-scroll font-semibold text-white"
    >
      To enjoy our newest app features tap the button below
    </div>
  </div>
  <div class="flex flex-col justify-between mt-10">
    <button
      (click)="openStore()"
      class="bg-[#FE565E] h-[60px] p-2 mx-auto w-[276px] text-white self-center place-items-center text-[20px] font-bold rounded-[10px]"
    >
      Update Now
    </button>
    @if(!shouldUpdate$()){

    <button
      (click)="closeDialog(updateDialog)"
      class="p-2 mx-auto w-[135px] text-white self-center place-items-center text-[20px] font-bold rounded-[10px]"
    >
      Skip
    </button>
    }
  </div>
</ng-template>
