import { inject } from "@angular/core";
import { Actions, createEffect, ofType, provideEffects } from "@ngrx/effects";
import { createActionGroup, createFeature, createReducer, createSelector, emptyProps, on, props } from "@ngrx/store";
import { NotificationService, SystemnotificationService } from "../systemnotification.service";
import { catchError, EMPTY, map, switchMap } from "rxjs";
import { FilterDTO } from "../app.model";

export interface SystemNotification {
    id: number;
    title: string;
    createdTime: string;  
    time?: string;
    createdBy: string;
    priority: 'MEDIUM' | 'LOW' | 'HIGH';  
    details: string;
    categoryId: number | null;  
    forAll: boolean;
    seen: boolean;
  }
  export interface Count{
    FORUM: number;
    NOTIFICATION: number;
  }
  export interface NotificationState {
    notifications: SystemNotification [] | null;
    error: string | null;
    loading: boolean;
    notificationCount: Count;
    filter: FilterDTO;
    currentPriorityIndex: number; 

  }
  // Initial state
  const initialState: NotificationState = {
    notifications: null,
    error: null,
    loading: false,
    filter: {
        pageNumber: 0,
        pageSize: 20, 
      },
    notificationCount: {
      FORUM: 0,
      NOTIFICATION: 0,
    },
    currentPriorityIndex:0
  };
  
  // Define actions for notifications
  export const NOTIFICATION_ACTIONS = createActionGroup({
    source: 'Notification API',
    events: {
      'Notification Load Request':props<{ filter: FilterDTO }>(),
      'Notification Load Success': props<{
        notifications: SystemNotification [];
      }>(),
      'Notification Load Failure': props<{ error: string }>(),
      'Update Notification Count Request': emptyProps(),
      'Notifications Reset': emptyProps(),
      'Update Notification Count': props<{
        count: Count;
      }>(),
      'Mark All Notification As Read' : props<{name: string}>(),
      'Mark Notification As Read': props<{ id: number }>(),
      'Mark Notification As Read Success': props<{ id: number }>(),
      'Mark Notification As Read Failure': props<{ error: string }>(),
      'change Next Transaction': emptyProps(),

    },
  });

  const notificationReducer = createReducer(
    initialState,
    
    // Handle Notification Load Request
    on(NOTIFICATION_ACTIONS.notificationLoadRequest, (state,action) => ({
      ...state,
      filter: action.filter,
      // currentPriorityIndex: 0,
      loading: true,
      error: null,
    })),
    
    // Handle Notification Load Success
    on(NOTIFICATION_ACTIONS.notificationLoadSuccess, (state, action) => ({
      ...state,
      notifications: action.notifications,
      loading: false,
    })),
    
    // Handle Notification Load Failure
    on(NOTIFICATION_ACTIONS.notificationLoadFailure, (state, action) => ({
      ...state,
      error: action.error,
      loading: false,
    })),
    
    // Handle Update Notification Count
    on(NOTIFICATION_ACTIONS.updateNotificationCountRequest, (state, action) => ({
        ...state,
    
      })),
    on(NOTIFICATION_ACTIONS.updateNotificationCount, (state, action) => ({
      ...state,
      notificationCount: action.count,
    })),
    on(NOTIFICATION_ACTIONS.notificationsReset, (state, action) => ({
      ...state,
      notifications: [],
    })),
  
    // Handle Mark Notification As Read Success
    on(NOTIFICATION_ACTIONS.markNotificationAsReadSuccess, (state, action) => ({
      ...state,
      notifications: state.notifications
    })),
    
    // Handle Mark Notification As Read Failure
    on(NOTIFICATION_ACTIONS.markNotificationAsReadFailure, (state, action) => ({
      ...state,
      error: action.error,
    })),
    on(NOTIFICATION_ACTIONS.changeNextTransaction, (state, action)=>{
      return {
        ...state,
        currentPriorityIndex: state.currentPriorityIndex+1
      }
    })
  );
  
  export const notificationFeature = createFeature({
    name: 'Notification',
    reducer: notificationReducer,
  });
 
  export const {selectNotifications, selectNotificationCount,selectCurrentPriorityIndex, selectNotificationState } = notificationFeature;
  export const selectCurrentPriorityNotification = createSelector(
    selectNotifications,
    selectCurrentPriorityIndex,
    (notifications, currentPriorityIndex) => {
      console.log(notifications,currentPriorityIndex,);
      if (notifications && currentPriorityIndex !== undefined) {

      return notifications[currentPriorityIndex]
      } else {
        console.log('Notifications or currentPriorityIndex are undefined');
        return null;
      }
    }
  );
  
  
  
  
  

  const loadNotificationsEffect = createEffect(
    (action$ = inject(Actions)) => {
      const notificationService = inject(SystemnotificationService);
      return action$.pipe(
        ofType(NOTIFICATION_ACTIONS.notificationLoadRequest),
        switchMap((action) =>
            notificationService.filterData(action.filter).pipe(
            catchError((error) => {
              NOTIFICATION_ACTIONS.notificationLoadFailure({ error: error.message });
              return EMPTY;
            }),
            map((notifications) =>
              NOTIFICATION_ACTIONS.notificationLoadSuccess({
                notifications : notifications.elements,
              })
            )
          )
        )
      );
    },
    { functional: true }
  );
  
  const updateNotificationCountEffect = createEffect(
    (action$ = inject(Actions)) => {
      const notificationService = inject(NotificationService);
      return action$.pipe(
        ofType(NOTIFICATION_ACTIONS.updateNotificationCountRequest), // Triggered action
        switchMap(() => 
          notificationService.getCount().pipe( // Call the service's `getCount()` method
            map((count) => 
              NOTIFICATION_ACTIONS.updateNotificationCount({ count }) // Dispatch the action with the fetched count
            ),
            catchError((error) => {
              console.error('Error fetching notification count', error);
              return EMPTY; // In case of error, return an empty observable
            })
          )
        )
      );
    },
    { functional: true }
  );
  
  export const notificationEffects = provideEffects (
  {  loadNotificationsEffect},
   { updateNotificationCountEffect},
  );